import { useState, useMemo } from "react";
import { testimonials } from "./resources/data/testimonials";
import { strings } from "./resources/data/strings";
import './styles/app.css'
import Testimonial from "./components/Testimonial";

const POSTS_PER_PAGE = 5;

function App() {

  const [currentPage, setCurrentPage] = useState(1);

  const maxPage = useMemo(() => {
     return Math.floor(testimonials.length / POSTS_PER_PAGE) + (testimonials.length % POSTS_PER_PAGE) * 1;
  }, [])

  const pageTestimonials = useMemo(() => {
    const thisPage = testimonials.slice((currentPage - 1) * POSTS_PER_PAGE, currentPage * POSTS_PER_PAGE);
    console.log(thisPage);
    return thisPage;
  }, [currentPage])

  return <>
    {/* Navigation bar */}
    <nav>
      <h1>{strings.name}</h1>
    </nav>

    {/* White page */}
    <main>

      {/* About section */}
      <section className="about">
        <h2>{strings.about}</h2>
        <article>
            <div className="text-box">
              <p>{strings.message_1}</p>
              <p>{strings.message_2}</p>
            </div>
            <img
              className="description-image" 
              src={require('./resources/media/image/description_image_1.jpg')}
              alt="Pack of German Shepherd dogs and their owners."
            />
        </article>
        <article>
          <img
              className="description-image" 
              src={require('./resources/media/image/description_image_2.jpg')}
              alt="German Shepherd dog running through field."
          />
          <div className="text-box">
            <p>{strings.message_3}</p>
          </div>
        </article>
      </section>

      {/* Testimonials */}
      <section className="testimonials">
        <h2>{strings.testimonials}</h2>
        <ul>
          {
            pageTestimonials.map(elem => {
              return <li key={elem.id}>
                <Testimonial testimonial={elem}/>
              </li>
            })
          }
        </ul>
        <div className="button-container">
          <p>page {currentPage} of {maxPage}</p>
          <div className="buttons">
            <button
              disabled={currentPage === 1}
              onClick={() => setCurrentPage(currentPage - 1)}
            >
              <span className="material-symbols-outlined">
                arrow_back
              </span>
            </button>
            <button
              disabled={currentPage === maxPage}
              onClick={() => setCurrentPage(currentPage + 1)}
            >
              <span className="material-symbols-outlined">
                arrow_forward
              </span>
            </button>
          </div>
        </div>
      </section>

    </main>
  </>
}

export default App;
