import React, { useMemo } from 'react'

const Testimonial = ({testimonial}) => {

const {id, media_type, owner_name, date, dog_name, text } = testimonial;

const media = useMemo(() => {
    const extention = media_type === 'image' ? 'jpg' : 'mp4';
    return media_type !== 'none' ? require(`../resources/media/${media_type}/testimonial_image_${id}.${extention}`) : null;
}, [id, media_type])

  return (
    <article className='testimonial'>
        {
            media_type === 'image' && <img src={media} alt="German Shepherd dog."/>
        }
        {
            media_type === 'video' && 
            <video controls>
                <source src={media} type="video/mp4"/>
            </video>
        }
        <div className='content'>
            <h3>{owner_name} - {date}{"\t"} - ({dog_name})</h3>
            <p>{text}</p>
        </div>
    </article>
  )
}

export default Testimonial