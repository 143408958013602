export const testimonials = [
    {
        id: 1,
        media_type: "image",
        owner_name: "Hayley",
        date: "22/08/22",
        dog_name: "Kaiser",
        text: "Hi Julian, I bought a male puppy from you back in April. Just wanted to let you know he's doing really well and getting a big boy now.",
    },
    {
        id: 2,
        media_type: "image",
        owner_name: "Julie and Rich",
        date: "07/08/22",
        dog_name: "Cruze",
        text: "Hi thought you might like to see how well and lovely Cruze is doing.",
    },
    {
        id: 3,
        media_type: "video",
        owner_name: "Claire",
        date: "22/05/22",
        dog_name: "Willow",
        text: "Willow is great and has settled into our family wonderfully.",
    },
    {
        id: 4,
        media_type: "image",
        owner_name: "Stephanie and Luke",
        date: "14/05/22",
        dog_name: "Vinnie",
        text: "Hi, here's a picture of Vinnie with his big sister."
    },
    {
        id: 5,
        media_type: "image",
        owner_name: "Gareth",
        date: "07/05/22",
        dog_name: "Bear",
        text: "Hi Julian, Bear is doing fantastic."
    },
    {
        id: 6,
        media_type: "image",
        owner_name: "David",
        date: "04/01/22",
        dog_name: "Cooper",
        text: "Cooper wes really good on the journey home and has settled well at home."
    },
    {
        id: 7,
        media_type: "image",
        owner_name: "Tony",
        date: "05/10/21",
        dog_name: "Mia",
        text: "Hi Julian, Mia's fantastic thanks. Planning to start agility with her, so she'll have plenty of stimulation and fun."
    },
    {
        id: 8,
        media_type: "image",
        owner_name: "Gemma",
        date: "22/09/21",
        dog_name: "Luna",
        text: "Growing way to fast, had her second jab on the 10th and been for her first little walk."
    },
    {
        id: 9,
        media_type: "image",
        owner_name: "Viv",
        date: "01/04/21",
        dog_name: "Bher",
        text: "Bher...he's doing well...xxx."
    },
    {
        id: 10,
        media_type: "none",
        owner_name: "Melissa and Mike",
        date: "04/03/21",
        dog_name: "Nala",
        text: "Hi, we just wanted to let you know that Nala has settled in so well. We have not had one accident in the house. The kids and the other dogs love her. She was the perfect choice for our family. Thank you so much. Melissa and Mike."
    },
    {
        id: 11,
        media_type: "none",
        owner_name: "Gary",
        date: "24/12/20",
        dog_name: "Mack",
        text: "Hi Anita and Julian... I bought a German Shephed pup from you about, gosh time flies, three years ago. Just to let you both know he has turned out to be a very loving, affectionate and very handsome dog. His name is Mack. Hope you both have a lovely Christmas, Gary."
    },
    {
        id: 12,
        media_type: "image",
        owner_name: "Sandra and Phil ",
        date: "17/10/20",
        dog_name: "Buddy",
        text: "Buddy is growing up fast, a big adorable boy. Kind regards, Sandra and Phil."
    },
    {
        id: 13,
        media_type: "image",
        owner_name: "Hannah",
        date: "02/02/20",
        dog_name: "Freddie",
        text: "Pupdate! The Fredster's getting big and he's almost had all his teeth through! Training is going well too."
    },
    {
        id: 14,
        media_type: "image",
        owner_name: "Shelley",
        date: "27/10/22",
        dog_name: "Elmo",
        text: "Hi Julian...Elmo is a nice healthy 45kg dog now."
    },
    {
        id: 15,
        media_type: "image",
        owner_name: "Claire",
        date: "06/12/19",
        dog_name: "Ralf",
        text: "Hi, here's an updated photo of Ralf. He has a wonderful temperament and has settled into the family very well."
    },
    {
        id: 16,
        media_type: "image",
        owner_name: "Sam",
        date: "26/10/19",
        dog_name: "Layla",
        text: "Hi it's Sam, she's settled in lovely, thank you so much."
    },
    {
        id: 17,
        media_type: "image",
        owner_name: "Sue",
        date: "09/08/22",
        dog_name: "Raven",
        text: "Raven is lovely, very much Pat's girl. Thanks Sue and Pat."
    },
    {
        id: 18,
        media_type: "image",
        owner_name: "Alison",
        date: "12/04/22",
        dog_name: "Charli",
        text: "Hi Julian, I thought you would like a little update on Charli. She is a wonderful pup, who is loved by all who meet her, even our postman likes her! She is gentle and well behaved. She is a massive attachment to me."
    },
    {
        id: 19,
        media_type: "image",
        owner_name: "Alphapackk9training",
        date: " 12/10/22",
        dog_name: "Ari",
        text: "We are made up with him, he's doing IGP too, will send some pics of him working once I've got some good ones."
    },
    {
        id: 20,
        media_type: "image",
        owner_name: "Krysia and Jan",
        date: "11/04/22",
        dog_name: "Ula and Tatra",
        text: "We love them to bits xxx."
    },
    {
        id: 21,
        media_type: "image",
        owner_name: "Beatrice",
        date: "08/04/22",
        dog_name: "Blaze",
        text: "Hello, just wanted to let you know that we have named our new puppy Blaze and he is doing fantastically well. He is very well behaved and very easy to train. Thank you very much!."
    },
];